exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-datenschutz-js": () => import("./../../../src/pages/impressum-datenschutz.js" /* webpackChunkName: "component---src-pages-impressum-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-was-js": () => import("./../../../src/pages/was.js" /* webpackChunkName: "component---src-pages-was-js" */),
  "component---src-pages-wer-js": () => import("./../../../src/pages/wer.js" /* webpackChunkName: "component---src-pages-wer-js" */),
  "component---src-pages-wie-js": () => import("./../../../src/pages/wie.js" /* webpackChunkName: "component---src-pages-wie-js" */),
  "component---src-pages-wo-js": () => import("./../../../src/pages/wo.js" /* webpackChunkName: "component---src-pages-wo-js" */),
  "component---src-templates-projekt-js": () => import("./../../../src/templates/projekt.js" /* webpackChunkName: "component---src-templates-projekt-js" */)
}

